import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import authenticationService from '../services/authentication.service';
import { useAuthentication } from '../session/AuthenticationProvider';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {InputPassword} from "../commons/components/input-password";

function Login({ history }) {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = useAuthentication();

  const login = async (e) => {
    e.preventDefault();
    authenticationService
      .signIn(email, password)
      .then((user) => {
        auth.login(user);
        history.push('/');
      })
      .catch((err) => {
        setError('Password o Email incorrectos');
        console.log(err);
      });
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form onSubmit={login}>
                    {error && <Alert color="danger">{error}</Alert>}
                    <h1>{t('login')}</h1>
                    <p className="text-muted">{t('loginMessage')}</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="email" name="email" placeholder={t('emailPlaceholder')} required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputPassword
                        placeholder={t('passwordPlaceholder')}
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={"password"}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4">
                          {t('login')}
                        </Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button tag={Link} to="/forgotpass" color="link" className="px-0">
                          {t('forgotPassword')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
