import React, { useContext, useState, createContext} from "react";

const BlobContext = createContext();

export const BlobProvider =({children}) => {
  const [blobs, setBlobs] = useState({});

  const setBlob =(fileId, blob) => {
    setBlobs(prevBlobs => ({
      ...prevBlobs,
      [fileId]: blob,
    }));
  };

  return (
    <BlobContext.Provider value={{blobs, setBlob}}>
      {children}
    </BlobContext.Provider>
  );
};

export const useBlobs = () => useContext(BlobContext);
