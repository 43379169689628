import axios from 'axios';
// import { getApiURL } from './axios/axios';

const API_URL = process.env.REACT_APP_MY_VARIABLE_API_URL;
class AuthenticationService {
  constructor() {
    this.getAuthUser = this.getAuthUser.bind(this);
    this.getToken = this.getToken.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getAuthUser() {
    return localStorage.getItem('user');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return this.getAuthUser() !== null;
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}login/`, { email: username, password: password })

        .then((data) => {
          const token = data.data.token.replace(/^"(.*)"$/, '$1');
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(data.data));
          resolve(data.data.user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  signUp(signUpData) {
    const { firstName, lastName, password, email } = { ...signUpData };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}users/`, {
          firstName,
          lastName,
          password,
          email,
          role: 'user',
        })

        .then((data) => {
          resolve(data.data.user);
        })
        .catch((err) => {});
    });
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  }

  recoveryPassword(email) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}reset-password`, { email: email })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  getByRecoveryToken(token, params = {}) {
    const sendData = {
      validateEmail: 0,
      ...params,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_URL}users/confirm`, sendData, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
